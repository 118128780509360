import * as React from 'react';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link } from "gatsby"
import HomeIcon from '@mui/icons-material/Home';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import GrainIcon from '@mui/icons-material/Grain';
import { BreadcrumbsContext } from "../../../context/BreadcrumbsContext"

function handleClick(event) {
    event.preventDefault();
    console.info('You clicked a breadcrumb.');
}

export default function GenericBreadcrumbs() {


    const {breadcrumbdata , setBreadcrumbdata} = React.useContext(BreadcrumbsContext);



    return (
        <div role="presentation" onClick={handleClick}>
            <Breadcrumbs aria-label="breadcrumb">
                {breadcrumbdata && breadcrumbdata.map((dt,id) => {
                    return (
                        <Link style={{textDecoration:'none',color:'grey'}} activeStyle={{textDecoration:'line'}} key={id} to={dt.path}>
                            {id === 0 && <HomeIcon sx={{ mr: 0.2,ml: 0.8 }} fontSize="inherit" />}
                            {dt.name}
                        </Link>
                    )
                })}
            </Breadcrumbs>
        </div>
    );
}