import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Container from '@mui/material/Container';
import { AppBar, Divider, Typography } from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import logoImage from '/src/images/logotransparent.png';

function Copyright() {
    return (
        <React.Fragment>
            {'© '}
            2023 - {new Date().getFullYear()} {' '}
            <Link href="https://www.indirimuzmani.com/" rel="sponsored" target="_blank" title="Flaticon">
                indirimuzmani.com
            </Link>

        </React.Fragment>
    );
}

const iconStyle = {
    width: 48,
    height: 48,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#34383b',
    mr: 1,
    '&:hover': {
        bgcolor: '#1b68b6',
    },
};

const iconStyleSvg = {
    color: '#a1afaf'
};



export default function Footer() {
    return (
        <AppBar position="static" sx={{ mt: '10px', backgroundColor: '#28323f' }}>
            <Container sx={{ my: 8, display: 'flex', bgcolor: '#28323f' }}>
                <Grid container spacing={9}>
                    <Grid item xs={8} sm={6} md={6} >

                        <Typography variant="h6" marked="left" gutterBottom>
                            Bizi Takip Edin
                        </Typography>


                        <Grid item sx={{ display: 'flex' }}>

                            <Box component="a" target="_blank" href="https://www.instagram.com/indirimuzmanicom" sx={iconStyle}>
                                <FacebookIcon sx={iconStyleSvg} />
                            </Box>
                            <Box component="a" target="_blank" href="https://www.instagram.com/indirimuzmanicom/" sx={iconStyle}>
                                <InstagramIcon sx={iconStyleSvg} />
                            </Box>
                        </Grid>

                    
                    </Grid>
                    <Grid item xs={4} sm={2} md={2}>
                        
                    </Grid>
                    <Grid item xs={8} sm={4} md={4}>

                        <img
                            src={logoImage}
                            alt="Ürün Resmi"
                            style={{ width: '200px' }}
                        />
                        <Box component="ul" sx={{ justifyContent: 'center', alignItems: 'center', m: 0, listStyle: 'none', p: 0 }}>
                            <Box component="li" sx={{ py: 1.5, ml: 4 }}>
                                <Typography fontSize={'0.7rem'} color={'#97a4a4'} variant="caption">
                                    Gerçek İndirimleri Kaçırma !
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item>
                        <Typography variant="caption">
                            {'İndirime giren ürünlerin fiyatları ve bulunabilirliği, belirtildiği tarih/saat '}
                            
                            {'itibariyle geçerlidir ve değişebilir. Satın alınmak istenen ürünün, o esanada görüntülenen fiyat ve stok bilgisi geçerli olacaktır.'}
                            
                            <Grid item>
                                <Copyright />
                            </Grid>
                        </Typography>

                    </Grid>
                </Grid>
            </Container>
        </AppBar>
    );
}