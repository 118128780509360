import React from "react"
import { Link } from "gatsby"
import {
  header,
   header__wrap,
   header__heading,
   header__nav,
header__link,
header__linkhome,
} from "./header.module.css"



 const Headereski = () => (
   <header className={header}>
     <div className={header__wrap}>
     <h1 style={{ textAlign: 'left' }} className={`${header__heading} ${header__linkhome}`}>
         <Link to="/app" className={`${header__link} ${header__linkhome}`}>
           İndirim 
           Uzmanı
         </Link>
       </h1>
       <nav role="main" className={header__nav}>
         <Link to="/app" className={header__link}>
           Home
         </Link>
         <Link to="/app/profile" className={header__link}>
           Profile
         </Link>
         <Link to="/app/tdetails" className={header__link}>
           Details
         </Link>
         <Link to="/app/login" className={header__link}>
           Login
         </Link>


       </nav>
     </div>
   </header>
 )

 export default Headereski

