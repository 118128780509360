import React, { useContext } from "react"

import { Header } from "./Header"

// Global styles and component-specific styles.
import "./global.css"
import { main } from "./main.module.css"
import GenericBreadcrumbs from "./Breadcrumb/GenericBreadcrumb"
import Footer from "./Footer/Footer"




export default function Layout ({ children }) { 

  
  
  
  return (
  <div>
    <Header />
    {/*<GenericBreadcrumbs />*/}
    <main className={main}>{children}</main>
    <Footer />
  </div>
)
}

