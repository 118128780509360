exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-js": () => import("./../../../src/pages/admin/[...].js" /* webpackChunkName: "component---src-pages-admin-js" */),
  "component---src-pages-details-amazon-card-detail-js": () => import("./../../../src/pages/details/AmazonCardDetail.js" /* webpackChunkName: "component---src-pages-details-amazon-card-detail-js" */),
  "component---src-pages-details-amazon-details-js": () => import("./../../../src/pages/details/AmazonDetails.js" /* webpackChunkName: "component---src-pages-details-amazon-details-js" */),
  "component---src-pages-details-amazon-search-card-detail-js": () => import("./../../../src/pages/details/AmazonSearchCardDetail.js" /* webpackChunkName: "component---src-pages-details-amazon-search-card-detail-js" */),
  "component---src-pages-details-amazon-search-details-js": () => import("./../../../src/pages/details/AmazonSearchDetails.js" /* webpackChunkName: "component---src-pages-details-amazon-search-details-js" */),
  "component---src-pages-details-hepsiburada-card-detail-js": () => import("./../../../src/pages/details/HepsiburadaCardDetail.js" /* webpackChunkName: "component---src-pages-details-hepsiburada-card-detail-js" */),
  "component---src-pages-details-hepsiburada-details-js": () => import("./../../../src/pages/details/HepsiburadaDetails.js" /* webpackChunkName: "component---src-pages-details-hepsiburada-details-js" */),
  "component---src-pages-details-hepsiburada-search-card-detail-js": () => import("./../../../src/pages/details/HepsiburadaSearchCardDetail.js" /* webpackChunkName: "component---src-pages-details-hepsiburada-search-card-detail-js" */),
  "component---src-pages-details-hepsiburada-search-details-js": () => import("./../../../src/pages/details/HepsiburadaSearchDetails.js" /* webpackChunkName: "component---src-pages-details-hepsiburada-search-details-js" */),
  "component---src-pages-details-trendyol-card-detail-js": () => import("./../../../src/pages/details/TrendyolCardDetail.js" /* webpackChunkName: "component---src-pages-details-trendyol-card-detail-js" */),
  "component---src-pages-details-trendyol-details-js": () => import("./../../../src/pages/details/TrendyolDetails.js" /* webpackChunkName: "component---src-pages-details-trendyol-details-js" */),
  "component---src-pages-details-trendyol-search-card-detail-js": () => import("./../../../src/pages/details/TrendyolSearchCardDetail.js" /* webpackChunkName: "component---src-pages-details-trendyol-search-card-detail-js" */),
  "component---src-pages-details-trendyol-search-details-js": () => import("./../../../src/pages/details/TrendyolSearchDetails.js" /* webpackChunkName: "component---src-pages-details-trendyol-search-details-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-signinup-sign-in-js": () => import("./../../../src/pages/signinup/SignIn.js" /* webpackChunkName: "component---src-pages-signinup-sign-in-js" */),
  "component---src-pages-signinup-sign-up-js": () => import("./../../../src/pages/signinup/SignUp.js" /* webpackChunkName: "component---src-pages-signinup-sign-up-js" */)
}

