import React from "react"

import AuthContextProvider from "./src/context/AuthContext"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import LoadingContextProvider from "./src/context/LoadingContext"
import BreadcrumbsContextProvider from "./src/context/BreadcrumbsContext"
import MenuContextProvider from "./src/context/MenuContext"
import Layout from "./src/components/Layout"
import { LocalizationProvider } from "@mui/x-date-pickers"



export const wrapRootElement = ({ element }) => (
  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="tr">
    <BreadcrumbsContextProvider>
      <LoadingContextProvider>
        <MenuContextProvider>
          <AuthContextProvider>

            {element}

          </AuthContextProvider>
        </MenuContextProvider>
      </LoadingContextProvider>
    </BreadcrumbsContextProvider>
  </LocalizationProvider>

)

// Client da yani browserda compoenntleri sarıyor
export const wrapPageElement = ({ element }) => {
  return <Layout>{element}</Layout>;
};